
import { defineComponent } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import PageContentSidebarList from '@/components/layout/pageContent/sidebar/PageContentSidebarList.vue'
import TmButton from '@/components/shared/TmButton.vue'
import type { PageContentSidebarItem } from '@/definitions/_layout/pageContent/sidebar/types'
import TmNotifications from '@/components/shared/TmNotifications.vue'

export default defineComponent({
  name: 'UI',
  components: {
    TmNotifications,
    TmButton,
    PageContentSidebarList,
    PageContent,
  },
  setup() {
    const viewsList: PageContentSidebarItem[] = [
      {
        title: 'Alerts & notifications',
        route: { name: 'ui.alerts' },
      },
      {
        title: 'Avatars & persons',
        route: { name: 'ui.avatars' },
      },
      {
        title: 'Breakpoints',
        route: { name: 'ui.breakpoints' },
      },
      {
        title: 'Buttons',
        route: { name: 'ui.buttons' },
      },
      {
        title: 'Charts',
        route: { name: 'ui.charts' },
      },
      {
        title: 'Chips',
        route: { name: 'ui.chips' },
      },
      {
        title: 'Countries and Flags',
        route: { name: 'ui.countries' },
      },
      {
        title: 'Color',
        route: { name: 'ui.color' },
      },
      {
        title: 'Dropdowns',
        route: { name: 'ui.dropdowns' },
      },
      {
        title: 'Emoji',
        route: { name: 'ui.emoji' },
      },
      {
        title: 'Expansion',
        route: { name: 'ui.expansion' },
      },
      {
        title: 'Fields',
        route: { name: 'ui.fields' },
      },
      {
        title: 'Files & attachments',
        route: { name: 'ui.files' },
      },
      {
        title: 'Icons',
        route: { name: 'ui.icons' },
      },
      {
        title: 'List',
        route: { name: 'ui.list' },
      },
      {
        title: 'Modals',
        route: { name: 'ui.modals' },
      },
      {
        title: 'Other',
        route: { name: 'ui.other' },
      },
      {
        title: 'Page loader',
        route: { name: 'ui.loader' },
      },
      {
        title: 'Skeleton & Loaders',
        route: { name: 'ui.progress' },
      },
      {
        title: 'Tabs',
        route: { name: 'ui.tabs' },
      },
      {
        title: 'Tables',
        route: { name: 'ui.tables' },
      },
      {
        title: 'Tooltips',
        route: { name: 'ui.tooltips' },
      },
      {
        title: 'Titles',
        route: { name: 'ui.titles' },
      },
      {
        title: 'Text',
        route: { name: 'ui.text' },
      },
      {
        title: 'Uploaders',
        route: { name: 'ui.uploaders' },
      },
    ]

    return {
      viewsList,
    }
  },
})
